<template>
  <div class="match-box" v-if="JSON.stringify(answers) !='{}'">
    <div class="flex list" v-for="(item1, index) in answers.item1" :key="index">
      <div class="flex1" :style="{'background': item1.image}">
        <div class="temp-img" v-if="item1.image">
          <a-image
          :width="width"
          :height="height"
          :src="OSSURL+'/'+item1.image"
          />
        </div>
        <div class="txt">{{item1.text}}</div>
      </div>
      <div class="line"></div>
      <div class="flex1 right right-flex">
        <div class="temp-img" v-if="answers.item2[index].image">
          <a-image
          :width="width"
          :height="height"
          :src="OSSURL+'/'+answers.item2[index].image"
          />
        </div>
        <div class="txt">{{answers.item2[index].text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { OSSURL } from '@/hooks/ali-oss'
export default defineComponent({
  props: {
    answers: {
      type: Object,
      default: () => {
        return {}
      }
    },
    width: {
      type: Number,
      default: 205
    },
    height: {
      type: Number,
      default: 110
    }
  },
  setup (props) {
    return {
      OSSURL
    }
  }
})
</script>

<style lang="scss" scoped>
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.temp-img{
  /deep/ .ant-image-img{
    height: 100%;
    object-fit: cover;
  }
}
.list{
  position: relative;
  z-index: 1;
  padding: 20px 0;
  &>.flex1{
    max-width: 45%;
  }
}
.flex1{
  display: flex;
  align-items: center;
}
.txt{
  padding-left: 20px;
  padding-right: 20px;
  background: #fff;
  line-height: 25px;
  flex: 1;
  word-break: break-all;
}
.flex{
  .line{
    position: absolute;
    z-index: -1;
    border-top: 1px solid #ddd;
    width: 100%;
    transform: translate(0, -50%);
  }
}
.match-box{
  overflow: hidden;
  .right-flex{
    flex-direction: row-reverse;
  }
}
</style>
