<template>
  <div>
    <span :class="{'blue-color': answers===true}">正确</span><span class="pt60" :class="{'blue-color': answers===false}">错误</span>
  </div>
  <div class="mt20">
    答案：{{ answers===true?'正确':'错误'}}
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    answers: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.flex{
  display: flex;
}
.pt60{
  padding-left: 60px;
}
.mt20{
  margin-top: 20px;
}
</style>
