<template>
  <div v-if="answers.length">
    <ul>
      <li class="temp-list" v-for="(item, index) in answers" :key="index">
        <div :class="{'blue-color': item.right}">
          {{getItem(index)}}、{{item.content.text}}
        </div>
        <div class="temp-img mt20" v-if="item.content && item.content.image">
          <a-image
          :width="width"
          :height="height"
          :src="OSSURL+'/'+item.content.image"
          />
        </div>
      </li>
    </ul>
    <div>
      答案：{{getAnswer().toString()}}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { OSSURL } from '@/hooks/ali-oss'
export default defineComponent({
  props: {
    answers: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: Number,
      default: 205
    },
    height: {
      type: Number,
      default: 110
    }
  },
  setup (props) {
    const getItem = (index) => {
      return `${String.fromCharCode(index + 65)}`
    }
    const getAnswer = () => {
      const _answers = []
      if (props.answers.length) {
        props.answers.filter((item, index) => {
          if (item.right) {
            _answers.push(`${String.fromCharCode(index + 65)}`)
          }
        })
      }
      return _answers
    }
    return {
      OSSURL,
      getItem,
      getAnswer
    }
  }
})
</script>

<style lang="scss" scoped>
.temp-img{
  /deep/ .ant-image-img{
    height: 100%;
    object-fit: cover;
  }
}
.temp-list{
  margin-top: 10px;
  margin-bottom: 10px;
}
.mt20{
  margin-top: 10px;
}
</style>
