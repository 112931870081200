<template>
  <h4 class="temp-title">{{question.text}}</h4>
  <div class="flex-top">
    <div class="temp-img" v-if="question.image">
      <a-image
      :width="width"
      :height="height"
      :src="OSSURL+'/'+question.image"
      />
    </div>
    <div class="temp-video" :style="{width: width+'px',height: height+'px'}" v-if="question.video">
      <video class="temp-video-box" :src="OSSURL+'/'+question.video"></video>
      <PlayCircleOutlined class="icon-play" @click="hanldePlay(OSSURL+'/'+question.video)"/>
    </div>
  </div>
  <slot></slot>
  <!-- 预览视频 -->
  <videoDialog v-model:href="videoUrl" v-model:visible="visibleVideo"></videoDialog>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { OSSURL } from '@/hooks/ali-oss'
import videoDialog from '@/components/videoDialog.vue'
import { PlayCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  props: {
    question: {
      type: Object,
      default: () => {
        return {}
      }
    },
    width: {
      type: Number,
      default: 205
    },
    height: {
      type: Number,
      default: 110
    }
  },
  components: {
    videoDialog,
    PlayCircleOutlined
  },
  setup () {
    const videoUrl = ref()
    const visibleVideo = ref(false)
    // 预览视频
    const hanldePlay = (url) => {
      visibleVideo.value = true
      videoUrl.value = url
    }
    return {
      OSSURL,
      videoUrl,
      visibleVideo,
      hanldePlay
    }
  }
})
</script>

<style lang="scss" scoped>
.temp-title{
  font-weight: bold;
  padding-bottom: 15px;
}
.flex-top{
  display: flex;
}
.temp-video{
  margin-left: 20px;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba($color: #000000, $alpha: .3);
  }
}
.temp-video-box{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 4px solid #206CFE;
}
.temp-img{
  /deep/ .ant-image-img{
    height: 100%;
    object-fit: cover;
  }
}
.icon-play{
  position: absolute;
  font-size: 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
}
</style>
