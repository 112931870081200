<template>
  <!-- 预览弹出层 -->
  <a-modal width="50%" :visible="value" title="预览" @cancel="handelCancel" :maskClosable="false" okText="确定" cancelText="取消">
    <div class="content">
      <commonTemp :question="question">
        <!-- 选择题 -->
        <template v-if="viewType === 1">
          <choiceTemp :answers="answers"></choiceTemp>
        </template>
        <!-- 判断题 -->
        <template v-if="viewType === 2">
          <judgeTemp :answers="answers"></judgeTemp>
        </template>
        <!-- 连线题 -->
        <template v-if="viewType === 3">
          <matchTemp :answers="answers"></matchTemp>
        </template>
      </commonTemp>
    </div>
    <template #footer>
      <a-button key="back" @click="handelCancel">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, watch, ref } from 'vue'
import commonTemp from '../components/commonTemp.vue'
import choiceTemp from './choiceTemp.vue'
import matchTemp from './matchTemp.vue'
import judgeTemp from './judgeTemp.vue'
import { fetchExerciseDetail } from '@/api/index'
import { Modal } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  props: {
    value: {},
    id: {},
    isEdit: {}
  },
  components: {
    commonTemp,
    choiceTemp,
    matchTemp,
    judgeTemp
  },
  setup (props, ctx) {
    const router = useRouter()
    const viewType = ref(0)
    const question = ref()
    const answers = ref()
    const showConfirms = () => {
      Modal.confirm({
        content: `${props.isEdit === 'add' ? '是否返回列表页面？' : '是否返回上一页面？'}`,
        onOk () {
          return new Promise(() => {
            // obj.callBack && obj.callBack()
            // if (props.isEdit === 'edit') {
            router.back()
            // }
            Modal.destroyAll()
          }).catch(() => {
            console.log('Oops errors!')
            Modal.destroyAll()
          })
        },
        cancelText: '取消',
        okText: '确认',
        onCancel () {
          Modal.destroyAll()
        }
      })
    }
    const handelCancel = () => {
      ctx.emit('update:value', false)
      ctx.emit('handelCancel')
    }
    const handleOk = () => {
      ctx.emit('update:value', false)
      if (props.isEdit) {
        showConfirms()
      }
    }
    const exerciseDetail = () => {
      fetchExerciseDetail({
        id: props.id
      }).then(res => {
        const _content = res.data.content
        viewType.value = res.data.type
        if (_content) {
          question.value = JSON.parse(_content).question
          answers.value = JSON.parse(_content).answers
        }
      })
    }
    watch(() => props.value, (newValue) => {
      if (newValue) {
        exerciseDetail()
      }
    })
    return {
      handelCancel,
      handleOk,
      question,
      answers,
      viewType
    }
  }
})
</script>

<style lang="scss" scoped>
.content{
  height: 580px;
  overflow-y: auto;
}
</style>
